import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import BlogCard from '@/components/blog/blog-card';
import FeaturedPost from '@/components/blog/featured-post';
import HubspotForm from '@/components/common/hubspot-newsletter';
import SEO from '@/components/seo';
import { useLocation } from '@reach/router';

const Blog = () => {
  const { pathname } = useLocation();

  const data = useStaticQuery(graphql`
    {
      allSanityBlogTags {
        nodes {
          name
          slug {
            current
          }
        }
      }
      allSanityBlogPosts(sort: { order: DESC, fields: date }) {
        nodes {
          _id
          title
          description
          date(formatString: "MMMM DD YYYY")
          isFeatured
          slug {
            current
          }
          author {
            name
            role
          }
          authors {
            name
            role
            image {
              asset {
                gatsbyImageData(width: 56)
              }
            }
          }
          tags {
            name
            slug {
              current
            }
          }
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          mainImageAlt
        }
      }
    }
  `);
  const tags = data.allSanityBlogTags.nodes;
  const posts = data.allSanityBlogPosts.nodes;
  const featuredPost = posts.filter((p) => p.isFeatured)[0] || posts[0];

  const [currentTag, setCurrentTag] = useState('');

  const filterPosts = () => {
    return posts
      .filter((p) => p._id !== featuredPost._id)
      .filter((post) => {
        if (currentTag === '') {
          return true;
        }
        return post.tags.some((tag) => tag.slug.current === currentTag);
      });
  };

  return (
    <Layout>
      <div className="pb-14 md:pb-24 lg:pb-32">
        <Container>
          <div>
            <div className="mt-16 md:mt-20">
              <FeaturedPost data={featuredPost} />
            </div>

            <div className="my-10 h-[1px] w-full bg-gradient-to-r from-orangey-yellow via-reddish-magenta to-purply-blue opacity-50 md:my-12 lg:my-16" />

            <div className="">
              {filterPosts().length > 0 && (
                <div>
                  <h2 className="text-3xl lg:text-4xl">Recent Posts</h2>
                </div>
              )}

              <div className="mt-8 flex flex-wrap gap-10 lg:flex-nowrap">
                {filterPosts().length > 0 && (
                  <div className="grid shrink grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                    {filterPosts().map((post, idx) => {
                      return (
                        <BlogCard data={post} idx={idx} key={`post-${idx}`} />
                      );
                    })}
                  </div>
                )}

                <div className="shrink-0 grow basis-[250px]">
                  <h2 className="text-xl md:text-3xl">All Tags</h2>

                  <div className="font-outfit mt-4 flex flex-wrap gap-3 font-light text-gray-700">
                    <button
                      onClick={() => setCurrentTag('')}
                      className={`flex rounded-[9px] bg-red-500 from-orangey-yellow via-reddish-magenta to-purply-blue p-[2px] ${
                        currentTag === ''
                          ? 'bg-gradient-to-tr'
                          : 'bg-transparent'
                      }`}
                    >
                      <span className="whitespace-nowrap rounded-[7px] bg-magnolia-500 px-3 py-1 duration-300">
                        All
                      </span>
                    </button>
                    {tags.map(({ name, slug }) => {
                      return (
                        <button
                          onClick={() => setCurrentTag(slug.current)}
                          className={`flex rounded-[9px] bg-red-500 from-orangey-yellow via-reddish-magenta to-purply-blue p-[2px] ${
                            slug.current === currentTag
                              ? 'bg-gradient-to-tr'
                              : 'bg-transparent'
                          }`}
                        >
                          <span className="whitespace-nowrap rounded-[7px] bg-magnolia-500 px-3 py-1 duration-300">
                            {name}
                          </span>
                        </button>
                      );
                    })}
                  </div>

                  <div className="mt-7">
                    <h3 className="text-lg md:text-xl">
                      Subscribe to Newsletter
                    </h3>
                    <HubspotForm
                      portalId="23114811"
                      formId="ac81cc8d-b237-4680-b711-bfcb987db902"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Blog;

export const Head = () => <SEO title="Blog" description="Galileo Blog posts" />;
